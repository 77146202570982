@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;700&display=swap);
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
  background-color: #f9f9f9;
}

body,
html {
  width: 100%;
  /* padding-right: 0px !important; */
  /* height: 100%; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.svg-cart {
  fill: "#ccc";
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

::-webkit-scrollbar {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 6px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #18181914;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #18181929;
}

#clynk-header {
  /* transition-duration: 200ms;
  transition-property: top, box-shadow, background-color; */
  transition: top 400ms, box-shadow 200ms, background-color 200ms;
}



